
import { mapGetters, mapActions, mapMutations } from "vuex"
import { FadeTransition } from "vue2-transitions"

import DarkModeMixin from "@/mixins/dark-mode"
import _ from "lodash"
import Search from "@/dashboard/search/sandwich-search"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
// const DateRangePicker = require('tiny-date-picker/date-range-picker')
import { PubSub } from "aws-amplify"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"

export default {
  name: "queryLayot",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    InsightsSidenavGlobal
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      subSuccess: null,
      subError: null
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      isImageUploading: "media/getIsUploading",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      uploadingErrorData: "media/getIsUploadingErrorData"
    }),
    datePickerRef() {
      return `datepicker_${ Math.floor(Math.random() * 10000000) }`
    }
  },
  created() {
    // this.listenForWebsocket()
  },
  destroyed() {
    // this.subSuccess.unsubscribe()
    // this.subError.unsubscribe()
  },
  watch: {
    uploadingError() {
      if (this.uploadingError === true) {
        let msg = `${ this.uploadingErrorData.length } images failed! `
        for (const item of this.uploadingErrorData) {
          msg += " " + item.file_name_original
        }
        this.toastError(msg, true)
      }
    },
    isImageUploading() {
      if (this.isImageUploading === true) {
        this.$nuxt.$loading.start()
      } else {
        this.$nuxt.$loading.finish()
      }
    }
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus"
    }),
    goBack() {
      this.$router.back()
    },
    listenForWebsocket() {
      this.subSuccess = PubSub.subscribe("update_cache_success").subscribe({
        next: (data) => {
          console.log("Message received charts", data)
          try {
            if (this.unitCacheStatus[data.value.account_id] !== undefined) {
              this.updateUnitCacheStatus({
                account_id: this.selectedAccountId,
                status: "success"
              })
              this.$bvToast.toast(data.value.message, {
                title: "Success",
                autoHideDelay: 8000,
                variant: "success",
                toaster: "b-toaster-bottom-right"
              })
            }
          } catch (e) {
            this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error(error)
          console.error("pubsub error", error)
        },
        close: () => console.log("Done")
      })
      this.subError = PubSub.subscribe("update_cache_error").subscribe({
        next: (data) => {
          console.log("Message received", data)
          try {
            if (this.unitCacheStatus[data.value.account_id] !== undefined) {
              this.updateUnitCacheStatus({
                account_id: this.selectedAccountId,
                status: "error"
              })
              this.toastError(data.value.message, true)
            }
          } catch (e) {
            this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error(error)
          console.error("pubsub error", error)
        },
        close: () => console.log("Done")
      })
    }
  }
}
