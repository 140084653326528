
import { mapMutations, mapActions, mapGetters } from "vuex"
import { FadeTransition } from "vue2-transitions"
import Search from "@/dashboard/search/sandwich-search-fuse"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
import DarkModeMixin from "@/mixins/dark-mode"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"

export default {
  name: "CalendarLayout",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    InsightsSidenavGlobal,
    NavbarBananaPage,
    NavbarBananaPageMobile
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
    }
  },

  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      isResetAccount: "layoutVars/getIsResetAccount",
      user: "user/getUser",

    }),
  },
  mounted() {
    this.setSelectedAccount(null)
    this.resetSidebar()
  },
  methods: {
    ...mapActions({
      resetSidebar: "navigation/resetSidebar",
      setSelectedAccount: "accounts/setSelectedAccount",
    }),
    ...mapMutations({
      updateSearchItems: "layoutVars/updateSearchItems",
    }),
    handleSelected(selected) {
      if (selected !== null) {
        this.updateSearchItems(selected)
      } else {
        this.updateSearchItems([])
      }
    },
    goBack() {
      this.$router.back()
    },
  },
}
