
import { mapGetters, mapMutations, mapActions } from "vuex"
import { FadeTransition } from "vue2-transitions"
import Search from "@/dashboard/search/sandwich-search"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
// import io from "socket.io-client"
import { PubSub } from "aws-amplify"
import localforage from "localforage"
import Multiselect from "vue-multiselect"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const LocationApi = ApiFactory.get("location")
import axios from "axios"
import { generateApiUrl } from "@/utils/helpers"
import * as _ from "lodash"
import DarkModeMixin from "@/mixins/dark-mode"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"
import { differenceInMinutes } from "date-fns"

export default {
  name: "DefaultLayout",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    Multiselect,
    NavbarBananaPage,
    NavbarBananaPageMobile
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      socket: null,
      socketStatus: {},
      subSuccess: null,
      subError: null,
      filteredLocations: [],
      unitSizes: null,
      filteredSizes: [],
      previousTimeout: null,
      userLocationPermissions: null
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      selectedAccount: "accounts/getSelectedAccount",
      isResetAccount: "layoutVars/getIsResetAccount",
      user: "user/getUser",
      isImageUploading: "media/getIsUploading",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      uploadingErrorData: "media/getIsUploadingErrorData",
      getUnitSizeFilters: "unitManager/getUnitSizeFilters",
      getUnitLocationFilters: "leads/filterLocations",
      locationsWithAddressTimeSet: "locations/getLocationsWithAddressTimeSet",
      locations: "locations/getLocationsWithAddress",
      selectedUnit: "unitManager/getSelectedUnitToEdit",
    }),
  },
  //   async asyncData({ params, store, error }) {
  // let userLocationPermissions = await this.userLocationsAndAccess()
  //       // console.log("🚀 ~ file: unitsManager.vue ~ line 204 ~ created ~ this.userLocationPermissions", this.userLocationPermissions)
  //       let filteredLocations = _.cloneDeep(store.getters["unitManager/getUnitLocationFilters"])
  //       let filteredSizes = _.cloneDeep(store.getters["unitManager/getUnitSizeFilters"])

  //       // if (filter) {
  //       //   this.filteredLocations = filter
  //       // }

  //       const relations = "address"
  //       let url = generateApiUrl(`${ this.selectedAccountId }/product-sizes`)
  //       let { data: res } = await axios.get(url, { headers: { "x-purge": true } })
  //        var unitSizes = res.data
  //       let locationsWithAddressTimeSet = store.getters["locations/getLocationsWithAddressTimeSet"]
  //       if (locationsWithAddressTimeSet == null) {
  //         await store.dispatch("locations/setLocationsWithAddress")
  //       } else {
  //         let diffInMins = differenceInMinutes(new Date(), new Date(locationsWithAddressTimeSet))
  //         if (diffInMins >= 30) {
  //           await store.dispatch("locations/setLocationsWithAddress")
  //         }
  //       }
  //       return {
  //         filteredLocations: filteredLocations,
  //         filteredSizes: filteredSizes,
  //         unitSizes: unitSizes,
  //         userLocationPermissions: userLocationPermissions,
  //       }
  //   },
  async created() {
    try {
      this.userLocationPermissions = await this.userLocationsAndAccess()
      // console.log("🚀 ~ file: unitsManager.vue ~ line 204 ~ created ~ this.userLocationPermissions", this.userLocationPermissions)
      let filter = await this.$vlf.getItem(`filterLocations_${ this.selectedAccountId }`)
      if (filter) {
        this.filteredLocations = filter
      }
      else {
        this.filteredLocations = _.cloneDeep(this.getUnitLocationFilters)
      }
      this.filteredSizes = _.cloneDeep(this.getUnitSizeFilters)
      // console.log("🚀 ~ file: unitsManager.vue ~ line 240 ~ mounted ~ filter", filter)

      // if (filter) {
      //   this.filteredLocations = filter
      // }

      // const relations = "address"
      let url = generateApiUrl(`${ this.selectedAccountId }/product-sizes`)
      let { data: res } = await axios.get(url, { headers: { "x-purge": true } })
      this.unitSizes = res.data

      // if (this.locationsWithAddressTimeSet == null) {
      //   this.$store.dispatch("locations/setLocationsWithAddress")
      // } else {
      //   let diffInMins = differenceInMinutes(new Date(), new Date(this.locationsWithAddressTimeSet))
      //   if (diffInMins >= 30) {
      //     this.$store.dispatch("locations/setLocationsWithAddress")
      //   }
      // }

      // this.locations = locations
      // Make async API call and mutate store
    } catch (e) {
      this.$rollbar.error('UnitManager: Error setting locations', e, { account_id: this.selectedAccountId })
      console.log("🚀 ~ file: unitsManager.vue ~ line 284 ~ created ~ e", e)
      this.isError = true
      this.toastError("Error loading locations")
    }
  },
  async mounted() {
    // this.listenForWebsocket()



    let cacheExpires = await this.$vlf.getItem("cache_expires")
    if (cacheExpires === null || cacheExpires < Date.now()) {
      var ONE_HOUR = 60 * 60 * 1000
      var EXPIRE_TIME = ONE_HOUR * 24
      var expires = new Date(new Date().getTime() + EXPIRE_TIME)
      localforage.clear()
      setTimeout(async () => {
        await this.$vlf.setItem("cache_expires", expires)
      })
    }
  },

  watch: {
    uploadingError: {
      handler() {
        if (this.uploadingError === true) {
          let msg = `${ this.uploadingErrorData.length } images failed! `
          for (const item of this.uploadingErrorData) {
            msg += " " + item.file_name_original
          }
          this.toastError(msg, true)
        }
      },
    },
    isImageUploading: {
      handler() {
        if (this.isImageUploading === true) {
          this.$nuxt.$loading.start()
        } else {
          this.$nuxt.$loading.finish()
        }
      },
    },
    getUnitSizeFilters: {
      handler(newVal, oldVal) {
        this.filteredSizes = newVal
      },
      deep: true,
    },
    filteredLocations: {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.setUnitLocationFilters(newVal)
          // console.log("🚀 ~ file: unitsManager.vue ~ line 272 ~ handler ~ this.filteredLocations newVal", newVal)
          await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, newVal)
        }
      },
      deep: true,
    },
    filteredSizes: {
      handler(newVal, oldVal) {
        this.setUnitSizeFilters(this.filteredSizes)
      },
      deep: true,
    },
    locations: {
      async handler() {
        let filter = await this.$vlf.getItem(`filterLocations_${ this.selectedAccount.id }`)
        if (filter && !this.filteredLocations) {
          this.filteredLocations = filter
        }
        else if (!this.filteredLocations && !filter) {
          this.filteredLocations = this.locations[0]
          await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, this.locations[0])
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
      updateSearchItems: "layoutVars/updateSearchItems",
      setUnitLocationFilters: "leads/updateFilterLocations",
    }),
    ...mapActions({
      setUnitSizeFilters: "unitManager/setUnitSizeFilters",
      userLocationsAndAccess: "user/userLocationsAndAccess",
    }),
    goBack() {
      this.$router.back()
    },

    listenForImageUploadEvents() {
      PubSub.subscribe("image upload error").subscribe({
        next: (data) => {
          this.toastError(`Image upload error: ${ data }`, true)
        },
        error: (error) => console.error(error),
      })
    },
    handleSelected(selected) {
      if (selected !== null) {
        this.updateSearchItems(selected)
      } else {
        this.updateSearchItems([])
      }
    },
    goBack() {
      this.$router.back()
    },
    listenForWebsocket() {
      this.subSuccess = PubSub.subscribe("update_cache_success").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            // this.updateUnitCacheStatus({
            //   account_id: this.selectedAccountId,
            //   status: 'success'
            // })
            this.$bvToast.toast(data.value.message, {
              title: "Success",
              autoHideDelay: 8000,
              variant: "success",
              toaster: "b-toaster-bottom-right",
            })
            // }
          } catch (e) {
            // this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error(error)
          console.error(error)
        },
      })
      this.subError = PubSub.subscribe("update_cache_error").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            this.updateUnitCacheStatus({
              account_id: this.selectedAccountId,
              status: "error",
            })
            this.toastError(data.value.message, true)
            // }
          } catch (e) {
            this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error(error)
          console.error(error)
        },
      })
    },
    /**
     * Reset unit size and location filters
     */
    onResetFilters() {
      // this.setUnitLocationFilters([])
      this.setUnitSizeFilters([])
      // this.filteredLocations = []
      this.filteredSizes = []
    },
  },
}
