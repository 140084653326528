import { render, staticRenderFns } from "./website-editor-page-schema.vue?vue&type=template&id=51751692&scoped=true&lang=pug"
import script from "./website-editor-page-schema.vue?vue&type=script&lang=js"
export * from "./website-editor-page-schema.vue?vue&type=script&lang=js"
import style0 from "./website-editor-page-schema.vue?vue&type=style&index=0&id=51751692&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51751692",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSidePanel: require('/dashboard/sidebar/base-side-panel/BaseSidePanel.vue').default,FeatherIcon: require('/helpers/FeatherIcon.vue').default,KeyboardShortcuts: require('/dashboard/modals/keyboard-shortcuts/KeyboardShortcuts.vue').default,WebsiteColorPalette: require('/dashboard/modals/website-color-palette/WebsiteColorPalette.vue').default,ContentFooter: require('/helpers/layouts/ContentFooter.vue').default})
