
import { config } from "@/config"
import { mapGetters, mapMutations, mapActions } from "vuex"
import { FadeTransition } from "vue2-transitions"
import Search from "@/dashboard/search/sandwich-search"
import AlgoliaSearch from "@/dashboard/search/algolia-search"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
// import io from "socket.io-client"
import { PubSub } from "aws-amplify"
import localforage from "localforage"
import DarkModeMixin from "@/mixins/dark-mode"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"
import Multiselect from "vue-multiselect"
import PugImg from "@/dashboard/pug-img"
// const AOS = require("aos")
import ContactStatusMixin from "@/mixins/contact-status"
import _ from "lodash"
import FeatherIcon from "@/helpers/FeatherIcon"
import PopupActivityCard from "@/dashboard/cards/popup-activity-card"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"

export default {
  name: "LeadsTableLayout",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    AlgoliaSearch,
    PugImg,
    Multiselect,
    FeatherIcon,
    InsightsSidenavGlobal,
    PopupActivityCard,
    NavbarBananaPage,
    NavbarBananaPageMobile
  },
  mixins: [DarkModeMixin, ContactStatusMixin],
  data() {
    return {
      items: [],
      filterLocations: null,
      filterStatus: [],
      socket: null,
      socketStatus: {},
      subSuccess: null,
      subError: null,
      isTestMode: false,
      testModePhone: '',
      dropDownLocations: null
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      filterLocationsVuex: "leads/filterLocations",
      filterStatusVuex: "leads/filterStatus",
      locations: "locations/getLocationsWithAddress",
      locationsWithAddress: "locations/getLocationsWithAddress",
      selectedAccount: "accounts/getSelectedAccount",
      isImageUploading: "media/getIsUploading",
      user: "user/getUser",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      isResetAccount: "layoutVars/getIsResetAccount",
      uploadingErrorData: "media/getIsUploadingErrorData",
      rhsClientCodes: "accounts/getRhsClientCodes",
    }),
    showTestMode() {
      return config.stage !== 'production'
    }
  },
  async created() {

    // if (filter) {
    //   this.filterLocations = filter
    // }
    // else {
    //   this.filterLocations = { id: 'all_locations', name: "All locations" }
    // }
    // else if (this.filterLocationsVuex && Object.keys(this.filterLocationsVuex).length) {

    //   this.filterLocations = _.cloneDeep(this.filterLocationsVuex)
    // }
  },
  async mounted() {
    // let filter = await this.$vlf.getItem(`filterLocations_${ this.selectedAccountId }`)
    // // let isRhs = _.findIndex(this.rhsClientCodes, (o) => { return o == this.selectedAccount.client_code })
    // this.isTestMode = _.clone(this.$store.getters['activityPopup/getIsTestMode'])
    // this.testModePhone = _.clone(this.$store.getters['activityPopup/getTestModePhone'])
    // // this.listenForWebsocket()
    //
    // // if (this.isDarkMode === true) {
    // //   document.getElementById("__nuxt").classList.add("pug-dark-mode")
    // // } else {
    // //   document.getElementById("__nuxt").classList.add("bg-light")
    // // }

    // this.filterStatus = _.cloneDeep(this.filterStatusVuex)
    // AOS.init({
    //   // Global settings:
    //   disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    //   startEvent: "load", // name of the event dispatched on the document, that AOS should initialize on
    //   initClassName: "aos-init", // class applied after initialization
    //   animatedClassName: "aos-animate", // class applied on animation
    //   useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    //   disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    //   debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    //   throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    //   // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    //   offset: 200, // offset (in px) from the original trigger point
    //   delay: 0, // values from 0 to 3000, with step 50ms
    //   duration: 200, // values from 0 to 3000, with step 50ms
    //   easing: "ease", // default easing for AOS animations
    //   once: false, // whether animation should happen only once - while scrolling down
    //   mirror: false, // whether elements should animate out while scrolling past them
    //   anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    // })
    // AOS.refreshHard()
    let cacheExpires = await this.$vlf.getItem("cache_expires")
    if (cacheExpires === null || cacheExpires < Date.now()) {
      var ONE_HOUR = 60 * 60 * 1000
      var EXPIRE_TIME = ONE_HOUR * 24
      var expires = new Date(new Date().getTime() + EXPIRE_TIME)
      localforage.clear()
      setTimeout(async () => {
        await this.$vlf.setItem("cache_expires", expires)
      })
    }
  },
  destroyed() {
    // this.subSuccess.unsubscribe()
    // this.subError.unsubscribe()
  },
  watch: {
    isTestMode() {
      this.$store.commit('activityPopup/setIsTestMode', this.isTestMode)
    },
    testModePhone() {
      this.$store.commit('activityPopup/setTestModePhone', this.testModePhone)
    },
    filterStatus() {
      this.updateFilterStatus(this.filterStatus)
    },
    async filterLocations() {
      // if (this.filterLocations && this.filterLocations.id == 'all_locations') {
      //   this.updateFilterLocations(null)
      //   await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, null)
      //   if (window && window.Intercom) {
      //     window.Intercom('update', { "filtered_location_id": null })
      //   }
      // }
      // else {
      //   this.updateFilterLocations(this.filterLocations)
      //   await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, this.filterLocations)
      //   if (window && window.Intercom) {
      //     window.Intercom('update', { "filtered_location_id": this.filterLocations.id })
      //   }
      // }
      // * set inventory if Unit Manager
      if (this.selectedAccount.use_insights_unit_manager) {
        try {
          await this.loadUnitsForLocations()
        } catch (e) {
          console.log(e)
        }
      }
    },
    uploadingError() {
      if (this.uploadingError === true) {
        let msg = `${ this.uploadingErrorData.length } images failed! `
        for (const item of this.uploadingErrorData) {
          msg += " " + item.file_name_original
        }
        this.toastError(msg, true)
      }
    },
    isImageUploading() {
      if (this.isImageUploading === true) {
        this.$nuxt.$loading.start()
      } else {
        this.$nuxt.$loading.finish()
      }
    },
    locations: {
      async handler() {
        if (this.locations) {
          this.dropDownLocations = _.cloneDeep(this.locations)
          this.dropDownLocations.unshift({ id: 'all_locations', name: "All locations" })
          // * set inventory if Unit Manager
          if (this.selectedAccount.use_insights_unit_manager) {
            try {
              await this.loadUnitsForLocations()
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
      updateSearchItems: "layoutVars/updateSearchItems",
      updateFilterStatus: "leads/updateFilterStatus",
      updateFilterLocations: "leads/updateFilterLocations",
    }),
    ...mapActions({
      loadUnitsForLocations: "unitManager/loadUnitsForLocations",
    }),
    getCookie(name) {
      var value = "; " + document.cookie
      var parts = value.split("; " + name + "=")
      if (parts.length == 2) return parts.pop().split(";").shift()
    },
    goBack() {
      this.$router.back()
    },
    // listenForImageUploadEvents() {
    //   PubSub.subscribe("image upload error").subscribe({
    //     next: (data) => {
    //       this.toastError(`Image upload error: ${ data }`, true)
    //     },
    //     error: (error) => console.error(error),
    //   })
    // },
    handleSelected(selected) {
      if (selected !== null) {
        this.updateSearchItems(selected)
      } else {
        this.updateSearchItems([])
      }
    },
    goBack() {
      this.$router.back()
    },
    listenForWebsocket() {
      this.subSuccess = PubSub.subscribe("update_cache_success").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            // this.updateUnitCacheStatus({
            //   account_id: this.selectedAccountId,
            //   status: 'success'
            // })
            this.$bvToast.toast(data.value.message, {
              title: "Success",
              autoHideDelay: 8000,
              variant: "success",
              toaster: "b-toaster-bottom-right",
            })
            // }
          } catch (e) {
            // this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error('LeadsTable CacheSuccess: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
      this.subError = PubSub.subscribe("update_cache_error").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            this.updateUnitCacheStatus({
              account_id: this.selectedAccountId,
              status: "error",
            })
            this.toastError(data.value.message, true)
            // }
          } catch (e) {
            this.$rollbar.error('LeadsTable CacheError: Error updating cache status', e, { account_id: this.selectedAccountId })
          }
        },
        error: (error) => {
          this.$rollbar.error('LeadsTable CacheError: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
    },
  },
}
