
import { mapGetters, mapMutations } from "vuex"
import { FadeTransition } from "vue2-transitions"
import Search from "@/dashboard/search/sandwich-search"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
// import io from "socket.io-client"
import { PubSub } from "aws-amplify"
import localforage from "localforage"
import DarkModeMixin from "@/mixins/dark-mode"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"

export default {
  name: "DefaultLayout",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    InsightsSidenavGlobal,
    NavbarBananaPage,
    NavbarBananaPageMobile
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      socket: null,
      socketStatus: {},
      subSuccess: null,
      subError: null,
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      // isDarkMode: "layoutVars/getIsDarkMode",
      selectedAccount: "accounts/getSelectedAccount",
      isImageUploading: "media/getIsUploading",
      user: "user/getUser",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      uploadingErrorData: "media/getIsUploadingErrorData",
      isResetAccount: "layoutVars/getIsResetAccount",
    }),
  },
  async created() { },
  async mounted() {
    // this.listenForWebsocket()

    // if (this.isDarkMode === true) {
    //   document.getElementById("__nuxt").classList.add("pug-dark-mode")
    // } else {
    //   document.getElementById("__nuxt").classList.add("bg-light")
    // }

    let cacheExpires = await this.$vlf.getItem("cache_expires")
    if (cacheExpires === null || cacheExpires < Date.now()) {
      var ONE_HOUR = 60 * 60 * 1000
      var EXPIRE_TIME = ONE_HOUR * 24
      var expires = new Date(new Date().getTime() + EXPIRE_TIME)
      localforage.clear()
      setTimeout(async () => {
        await this.$vlf.setItem("cache_expires", expires)
      })
    }
  },
  destroyed() {
    // this.subSuccess.unsubscribe()
    // this.subError.unsubscribe()
  },
  watch: {
    // isDarkMode() {
    //   if (this.isDarkMode === true) {
    //     document.getElementById("__nuxt").classList.remove("bg-light")
    //     document.getElementById("__nuxt").classList.add("pug-dark-mode")
    //   } else if (this.isDarkMode === false) {
    //     document.getElementById("__nuxt").classList.add("bg-light")
    //     document.getElementById("__nuxt").classList.remove("pug-dark-mode")
    //   }
    // },
    uploadingError() {
      if (this.uploadingError === true) {
        let msg = `${ this.uploadingErrorData.length } images failed! `
        for (const item of this.uploadingErrorData) {
          msg += " " + item.file_name_original
        }
        this.toastError(msg, true)
      }
    },
    isImageUploading() {
      if (this.isImageUploading === true) {
        this.$nuxt.$loading.start()
      } else {
        this.$nuxt.$loading.finish()
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
      updateSearchItems: "layoutVars/updateSearchItems",
    }),
    getCookie(name) {
      var value = "; " + document.cookie
      var parts = value.split("; " + name + "=")
      if (parts.length == 2) return parts.pop().split(";").shift()
    },
    goBack() {
      this.$router.back()
    },
    listenForImageUploadEvents() {
      PubSub.subscribe("image upload error").subscribe({
        next: (data) => {
          this.toastError(`Image upload error: ${ data }`, true)
        },
        error: (error) => console.error(error),
      })
    },
    handleSelected(selected) {
      if (selected !== null) {
        this.updateSearchItems(selected)
      } else {
        this.updateSearchItems([])
      }
    },
    goBack() {
      this.$router.back()
    },
    listenForWebsocket() {
      this.subSuccess = PubSub.subscribe("update_cache_success").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            // this.updateUnitCacheStatus({
            //   account_id: this.selectedAccountId,
            //   status: 'success'
            // })
            this.$bvToast.toast(data.value.message, {
              title: "Success",
              autoHideDelay: 8000,
              variant: "success",
              toaster: "b-toaster-bottom-right",
            })
            // }
          } catch (e) {
            // this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error('Popup CacheSuccess: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
      this.subError = PubSub.subscribe("update_cache_error").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            this.updateUnitCacheStatus({
              account_id: this.selectedAccountId,
              status: "error",
            })
            this.toastError(data.value.message, true)
            // }
          } catch (e) {
            this.$rollbar.error('Popup CacheError: Error updating cache status', e, { account_id: this.selectedAccountId })
          }
        },
        error: (error) => {
          this.$rollbar.error('Popup CacheError: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
    },
  },
}
