
import * as uuid from "uuid/v1"
import { isEqual, cloneDeep, debounce, unset, isArray } from "lodash"
import { FadeTransition } from "vue2-transitions"
import { mapGetters, mapActions } from "vuex"
import Multiselect from "vue-multiselect"
import Navbar from "@/dashboard/website-editor/navbar-halloumi"
import KeyboardShortcuts from "@/dashboard/modals/keyboard-shortcuts"
import WebsiteColorPalette from "@/dashboard/modals/website-color-palette"
import draggable from "vuedraggable"
import { ApiFactory } from "@/utils/apis/ApiFactory"
import { format } from "date-fns"
import { formatToTimeZone, parseFromTimeZone } from "date-fns-timezone"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import EditorSidebar from "@/helpers/website-editor/EditorSidebarHashbrown"
import BaseSidePanel from "@/dashboard/sidebar/base-side-panel"
import FeatherIcon from "@/helpers/FeatherIcon"
import { removeAndAlterIds } from "@/dashboard/utils/site-builder"

const PageApi = ApiFactory.get("page")
const LocationApi = ApiFactory.get("location")
import { EventBus } from "@/utils/event-bus"
// import { PubSub } from "aws-amplify"
import DarkModeMixin from "@/mixins/dark-mode"
var Convert = require('ansi-to-html')
var convert = new Convert({
  fg: '#000',
  bg: '#000',
  newline: false,
  escapeXML: false,
  stream: false
})

export default {
  watchQuery: true,
  components: {
    draggable,
    ContentFooter,
    FadeTransition,
    Navbar,
    EditorSidebar,
    Multiselect,
    KeyboardShortcuts,
    WebsiteColorPalette,
    FeatherIcon
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      displayRightSidebar: false,
      displayLeftSidebar: false,
      cards: [],
      showRightSidebar: false,
      isLoading: false,
      showLeftSidebar: true,
      components: [],
      cachedPageSettings: null,
      cachedPageObject: null,
      socket: null,
      changeDetails: null,
      changeDetailsModalTitle: null,
      previousTimeout: null,
      multiselectValue: null,
      showHistorySidebar: false,
      pageHistoryLoadedNumber: null,
      pageHistryItems: [],
      format,
      formatToTimeZone,
      parseFromTimeZone,
      multiselectOptions: [
        {
          title: "New Page",
          action: "new-page",
        },
        {
          title: "Location Page",
          action: "location-page",
        },
      ],
      shortcuts: [],
    }
  },
  async fetch() {
    try {
      if (!this.sitePages || !this.sitePages.length) {
        const website = this.selectedWebsite
        // Make async API call and mutate store
        const { data: response } = await PageApi.getPagesReference(website.id)
        const sorted = response.data.sort(function (a, b) {
          var nameA = a.name.toLowerCase()
          var nameB = b.name.toLowerCase()
          if (nameA < nameB) {
            // sort string ascending
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0 // default return value (no sorting)
        })
        // Set page store
        await this.$store.dispatch("page/setPages", sorted)
      }
    } catch (error) {
      this.$rollbar.error('WebsiteEditor: Error fetching pages', error, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id })
    }
  },
  computed: {
    ...mapGetters({
      pageSettings: "editorSidebar/getPageSettings",
      formSettings: "editorSidebar/getFormSettings",
      selectedAccountId: "user/getSelectedAccountId",
      loggedInUser: "user/getUser",
      selectedWebsite: "website/getSelectedWebsite",
      selectedPage: "editorSidebar/getSelectedPage",
      locationPages: "editorSidebar/getLocationPages",
      isEditorAddPage: "editorSidebar/getIsEditorAddPage",
      pages: "page/getPages",
      sitePages: "page/getSitePages",
      componentSchemas: "editorSidebar/getComponents",
    }),
    hasChanges() {
      let tmp = this.buildSettingsWithoutGlobal()
      return !isEqual(tmp, this.cachedPageObject)
    },
    formattedComponents() {
      return _.groupBy(_.cloneDeep(this.componentSchemas), "type")
    }
  },
  watch: {
    /**
     * Update cache once a new page has been saved
     */
    pageSettings: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && oldVal.is_new_page && !newVal.is_new_page) {
          this.cacheSettings()
          this.multiselectValue = this.selectedPage
        }
      },
      deep: true,
      immediate: false,
    },
    showHistorySidebar: {
      handler() {
        if (this.showHistorySidebar === true) {
          this.loadPageHistory()
          // this.$refs['history-sidenav'].style.width = '375px'
          this.$refs["history-sidenav-wrap"].style.width = "100vw"
        } else {
          // this.$refs['history-sidenav'].style.width = '0px'
          this.$refs["history-sidenav-wrap"].style.width = "0px"
        }
      },
    },
    selectedPage: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          // Cache page state
          this.cacheSettings()
        }
      },
      deep: true,
      immediate: true,
    },
    formSettings: {
      handler(newVal, oldVal) {
        if (newVal && newVal.type !== undefined) {
          clearTimeout(this.previousTimeout)
          this.previousTimeout = setTimeout(() => {
            this.sendSelectedElementEvent(newVal.id, newVal.type)
          }, 200)
        }
      },
    },
    /**
     * Watch changes and update the store value
     */
    hasChanges(newVal, oldVal) {
      this.$store.commit("editorSidebar/UPDATE_EDITOR_HAS_CHANGES", newVal)
    },
  },
  mounted() {
    // Cache page state
    this.cacheSettings()
    // this.socket = this.$nuxtSocket({
    //   name: 'preview'
    // })
    // this.socket.on('connect', () => {
    //   console.log(this.socket.id) // 'G5p5...'
    // })
    // Set multi-select value
    this.multiselectValue = this.selectedPage

    this.$nextTick(() => {
      window.addEventListener("beforeunload", this.onHandleUnload)
    })
    EventBus.$on('show_editor_sidebar', (data) => {
      this.showRightSidebar = true
    })
  },
  beforeDestroy() {
    // Reset slected page in store before destroying page
    this.selectPageSettings(null)
    this.selectFormSettings(null)
    this.setSelectedPage(null)
    this.setLocationPages(null)
    EventBus.$off('show_editor_sidebar')
    // Stop listening to events
    window.removeEventListener("beforeunload", this.onHandleUnload)

    // Kill any loading
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      savePageSettings: "editorSidebar/savePageSettings",
      saveLocationPage: "editorSidebar/saveLocationPage",
      applyPageSettings: "editorSidebar/applyPageSettings",
      updatePageSettings: "editorSidebar/updatePageSettings",
      selectFormSettings: "editorSidebar/selectFormSettings",
      selectPageSettings: "editorSidebar/selectPageSettings",
      setSelectedPage: "editorSidebar/setSelectedPage",
      setLocationPages: "editorSidebar/setLocationPages",
    }),
    toggleSideBar() {
      this.showRightSidebar = !this.showRightSidebar
    },
    toggleLeftSideBar() {
      this.showLeftSidebar = !this.showLeftSidebar
    },
    showChangeDetails(item) {
      this.changeDetails = null
      this.changeDetailsModalTitle = `Version ${ item.page_version } Changes`
      this.$db.collection(`changelog`).doc(item.changelog_id).get().then((doc) => {
        if (doc.exists) {
          let tmp = doc.data()
          this.changeDetails = tmp.diff ? tmp.diff : ""
          this.$bvModal.show('change-details')
        }
      })
    },
    formatLogs(log) {
      let tmp = convert.toHtml(log) // .replace(/\\n/g, '<br/>').replace()
      return tmp
    },
    hideWrap() {
      this.$refs["history-sidenav-wrap"].style.width = "0px"
      this.showHistorySidebar = false
      this.pageHistoryLoadedNumber = null
      this.pageHistryItems = []
    },
    async sendSelectedElementEvent(id, type) {
      try {
        if (this.selectedPage && this.selectedPage.id) {
          let data = { id, type, page_id: this.selectedPage.id }
          // this.socket.emit('scrollToElement', data)
          // await PubSub.publish("scroll to element", data)
        }
      } catch (error) {
        console.error("Pub Sub: ", error)
      }
    },
    async loadPageHistory() {
      try {
        this.$wait.start("loading history")
        // let { data: res } = await PageApi.getPageHistory(this.cachedPageSettings)
        let query = this.$db.collection(`page_version_history/${ this.selectedWebsite.id }/history`).where("page_id", "==", this.cachedPageSettings.id).orderBy("created_at", "desc")

        if (this.pageHistoryLoadedNumber) {
          query = query.startAfter(this.pageHistoryLoadedNumber)
        }

        query.limit(25).get().then((querySnapshot) => {
          // this.pageHistryItems = data
          if (querySnapshot.size < 25) {
            this.pageHistoryLoadedNumber = null
          }
          else {
            this.pageHistoryLoadedNumber = querySnapshot.docs[querySnapshot.docs.length - 1]
          }
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let item = doc.data()
            item.created_at = item.created_at.toDate()
            this.pageHistryItems.push(item)
          })
        })
      } catch (e) {
        // console.error("Page History: ", e)
        this.$rollbar.error('WebsiteEditor: Error loading page history', e, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id })
      }
      this.$wait.end("loading history")
    },
    toggleShowHistorySidebar() {
      this.showHistorySidebar = !this.showHistorySidebar // true
    },
    resetPageToOlderVersion(oldVersion) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to restore a previous version? Any unsaved changed will be lost forever", {
          title: "Restore page",
          cancelTitle: `Cancel`,
          cancelVariant: "transparent",
          okTitle: "Yes, restore page",
          okVariant: "warning",
          footerBorderVariant: "white",
          centered: true,
          hideHeaderClose: false,
        })
        .then(async (value) => {
          if (value) {
            // this.$router.back()
            try {
              // let { data: res } = await PageApi.getPageHistoryItem(this.cachedPageSettings, oldVersion)
              var parsedPage = JSON.parse(oldVersion.page_object)
              var sections_object = JSON.parse(parsedPage.sections_object)
              try {
                let so = JSON.parse(sections_object)
                sections_object = so
              } catch (e) { }
              unset(parsedPage, "sections")
              unset(parsedPage, "website")
              parsedPage.sections_object = sections_object
              this.isLoading = true
              this.$wait.start("page-saving")
            } catch (e) {
              this.$rollbar.warning('Error getting page history item', e)
              this.toggleShowHistorySidebar()
              this.toastError("Error restoring page")
            }

            try {
              let resp = await this.updatePageSettings(parsedPage)
              this.toggleShowHistorySidebar()
              if (resp.success) {
                this.toastSuccess("Successfully restored page.")

                /**
                 * After page history is restored
                 * We use the go router method to refresh the page, so
                 * the page reloads and we reset the `hasChanges` method
                 * Yes, this isn't best practice, but that's where we are
                 * at for now. We would need to refactor how pages are loaded
                 * into the editor, so we don't have to refresh the page.
                 */
                this.$router.go()

                // Recache updates
                this.cacheSettings()
              } else {
                this.$rollbar.error('WebsiteEditor: Error restoring page', resp, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id })
                this.toastError("An error occurred when restoring the page.")
              }
            } catch (e) {
              console.error("error: ", e)
              this.toggleShowHistorySidebar()
              this.$rollbar.error('WebsiteEditor: Error restoring page', e, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id })
              this.toastError("An error occurred when restoring the page.")
            }
            this.isLoading = false
            this.$wait.end("page-saving")
          }
        })
    },
    onPreviewPage() {
      if (this.selectedPage && this.selectedPage.id) {
        const baseEditorRoute = `/website-manager/${ this.selectedAccountId }/editor`

        const routeData = this.$router.resolve(`${ baseEditorRoute }/${ this.selectedPage.id }/view`)
        window.open(routeData.href, "_tab")
      }
    },
    /**
     * Return name of location for multi-select label
     */
    customLabel({ location }) {
      return location.name
    },
    /**
     * Return name of location for multi-select label
     */
    customPageLabel(event) {
      return event.name || event.title
    },
    /**
     * Cache settings so we can track changes
     */
    cacheSettings() {
      this.cachedPageSettings = cloneDeep(this.pageSettings)
      this.cachedPageObject = this.buildSettingsWithoutGlobal()
    },
    buildSettingsWithoutGlobal() {
      let data = cloneDeep(this.pageSettings)

      if (this.pageSettings && this.pageSettings.sections_object) {
        data.sections_object = []
        for (let sect of this.pageSettings.sections_object) {
          if (!sect.is_global) data.sections_object.push(sect)
        }
        return data
      } else {
        return data
      }
    },
    /**
     * Handle any unload events
     */
    onHandleUnload(event) {
      if (this.hasChanges) {
        const unsaved_changes_warning = "You have unsaved changes. Are you sure you wish to leave?"
        event.returnValue = ""
      }
    },
    /**
     * Go back to previous page
     */
    onBack() {
      if (this.hasChanges) {
        this.$router.back()
      } else {
        this.$router.push(`/website-manager/${ this.selectedAccountId }/editor`)
      }
    },
    /**
     * Edit selected page. Update the sidebar editor settings
     * with the selected page settings.
     */
    onEditPage() {
      this.selectFormSettings({ ...this.pageSettings, ...{ type: "page" } })
    },
    /**
     * Save new page to database.
     */
    onSavePage() {
      this.isLoading = true
      this.$wait.start("page-saving")
      const parsedPage = JSON.parse(JSON.stringify(this.pageSettings))
      this.savePageSettings(parsedPage)
        .then((resp) => {
          if (resp.success) {
            this.toastSuccess("Successfully saved page.")

            // Redirect to edit page after creating page
            if (resp.page && resp.page.id) {
              // const baseEditorRoute = `/website-manager/[account_id]/editor`
              const baseEditorRoute = `/website-manager/${ this.selectedAccountId }/editor`
              this.$router.replace(`${ baseEditorRoute }/${ resp.page.id }`)

              // Recache updates
              this.cacheSettings()
            }
          }

          if (!resp.success) {
            this.$rollbar.error('WebsiteEditor: Error saving the page', resp, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id, page_id: this.pageSettings.id })
            this.toastError("An error occurred when saving the page.")
          }
        })
        .catch((err) => {
          this.$rollbar.error('WebsiteEditor: Error saving the page', err, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id, page_id: this.pageSettings.id })
          this.toastError("An error occurred when saving the page.")
        })
        .finally(() => {
          this.isLoading = false
          this.$wait.end("page-saving")
        })
    },
    /**
     * Confirm that we want to save to multiple locations
     */
    async onConfirmLocationSave() {
      const { data: response } = await LocationApi.all(this.selectedAccountId, null, null, 1000000)
      const locationsLength = response.num_items
      try {
        this.$bvModal
          .msgBoxConfirm(`Saving this page will create ${ locationsLength } location pages. Are you sure you want to do this?`, {
            title: "Create Location Pages",
            cancelTitle: `Cancel`,
            cancelVariant: "transparent",
            okTitle: "Create location pages",
            okVariant: "primary",
            footerBorderVariant: "white",
            centered: true,
            hideHeaderClose: false,
          })
          .then((value) => {
            if (value) {
              this.onSaveLocationPage()
            }
          })
      } catch (error) {
        this.toastError("An error occurred when saving the page.")
      }
    },
    /**
     * Confirm that we want to save to multiple locations
     */
    async onConvertLocationSave() {
      const { data: response } = await LocationApi.all(this.selectedAccountId, null, null, 1000000)
      const locationsLength = response.num_items
      try {
        this.$bvModal
          .msgBoxConfirm(`Saving this page will create ${ locationsLength } location pages. Are you sure you want to do this?`, {
            title: "Create Location Pages",
            cancelTitle: `Cancel`,
            cancelVariant: "transparent",
            okTitle: "Create location pages",
            okVariant: "primary",
            footerBorderVariant: "white",
            centered: true,
            hideHeaderClose: false,
          })
          .then((value) => {
            if (value) {
              const parsedPage = JSON.parse(JSON.stringify(this.pageSettings))
              parsedPage.is_location = true
              delete parsedPage.id
              parsedPage.route = ""
              this.onSaveLocationPage(parsedPage)
            }
          })
      } catch (error) {
        this.toastError("An error occurred when saving the page.")
      }
    },
    /**
     * Save new location page to database
     */
    onSaveLocationPage(pageSettings = null) {
      this.isLoading = true
      this.$wait.start("page-saving")
      let parsedPage
      if (pageSettings) {
        parsedPage = JSON.parse(JSON.stringify(pageSettings))
      } else {
        parsedPage = JSON.parse(JSON.stringify(this.pageSettings))
      }

      var pageOrigDiff = JSON.parse(JSON.stringify(this.cachedPageObject))
      var pageDiff = JSON.parse(JSON.stringify(parsedPage))

      // Add lasted edited user to the page object
      if (this.loggedInUser) {
        parsedPage.last_edited_by = this.loggedInUser
      }

      this.saveLocationPage(parsedPage)
        .then((resp) => {
          if (resp.success) {
            this.toastSuccess("Successfully saved location pages.")

            // Redirect to edit page after creating page
            if (resp.page && resp.page.id) {
              const baseEditorRoute = `/website-manager/${ this.selectedAccountId }/editor`
              this.$router.replace(`${ baseEditorRoute }/${ resp.page.id }`, () => {
                // After route navigation completes
                // We use the go router method to refresh the page, so
                // the page reloads and we reset the `hasChanges` method
                this.$router.go()
              })

              delete pageDiff.last_edited_by
              delete pageDiff.style_option
              delete pageOrigDiff.last_edited_by
              delete pageOrigDiff.style_option
              pageDiff = this.removeUneededPageItems(pageDiff)
              pageOrigDiff = this.removeUneededPageItems(pageOrigDiff)

              this.$root.setWebsiteChanges(`Update Page: ${ parsedPage.name } - ${ parsedPage.route }`, this.$jsonDiff.diffString(pageOrigDiff, pageDiff), 'editor_page').then((id) => {
                let versionHistory = {}
                versionHistory.page_version = resp.page.version
                versionHistory.page_object = JSON.stringify(resp.page)
                versionHistory.page_id = resp.page.id
                versionHistory.created_at = new Date()
                versionHistory.changelog_id = id
                // delete versionHistory.last_edited_by
                versionHistory.last_edited_by_id = this.loggedInUser.id
                versionHistory.last_edited_by_name = this.loggedInUser.name
                versionHistory.last_edited_by_email = this.loggedInUser.email
                this.$db.collection(`page_version_history/${ resp.page.website_id }/history`).add(versionHistory).then((docRef) => {

                }).catch((e) => {
                  console.log(e)
                })
              })
              // Recache updates
              this.cacheSettings()

              // Stop loading
              this.isLoading = false
              this.$wait.end("page-saving")
            }
          } else {
            this.toastError("An error occurred when saving location pages.")
            this.isLoading = false
            this.$wait.end("page-saving")
          }
        })
        .catch((err) => {
          console.error("error: ", err)
          this.$rollbar.error('WebsiteEditor: Error saving location page', err, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id, page_id: pageSettings.id || this.pageSettings.id })
          this.toastError("An error occurred when saving the page.")
          this.isLoading = false
          this.$wait.end("page-saving")
        })
    },
    async onCopyPage() {
      try {
        const pageSections = cloneDeep(this.pageSettings.sections_object)
        await navigator.clipboard.writeText(JSON.stringify({ type: "page", data: pageSections }))
        this.toastSuccess("Copied page to clipboard!")
      } catch (e) {
        console.log("🚀 ~ file: website-editor.vue:901 ~ onCopyPage ~ e:", e)
        this.toastError("An error occurred when trying to copy the pages")
      }
    },
    async onPastePage() {
      try {
        const page = JSON.parse(await navigator.clipboard.readText())
        const newSections = []

        if (page.type === "page" && page.data && isArray(page.data)) {
          for (let section of page.data) {
            if (section && section.rows && isArray(section.rows) && section.index !== undefined && section.index !== null) {
              // Scrub ids and create new unique ids
              removeAndAlterIds(section, this.selectedAccountId)
              const sectionCopy = {
                ...section,
                id: section.id ? section.id : uuid()
              }
              newSections.push(sectionCopy)
            }
            else {
              console.log(JSON.stringify(section, null, 2))
              throw new Error("not a section")
            }
          }
        }
        else {
          console.log(JSON.stringify(page, null, 2))
          throw new Error("not a page")
        }

        const selectedPage = cloneDeep(this.pageSettings)
        selectedPage.sections_object = newSections

        // Remove from sidebar settings
        this.selectFormSettings(null)

        this.applyPageSettings(selectedPage)
      } catch (e) {
        console.error(e)
        this.toastError("Cannot paste page. Invalid type.")
      }
    },
    /**
     * Update the selected page in the database.
     */
    onUpdatePage() {
      this.isLoading = true
      this.$wait.start("page-saving")

      const parsedPage = JSON.parse(JSON.stringify(this.pageSettings))

      var pageOrigDiff = JSON.parse(JSON.stringify(this.cachedPageObject))
      var pageDiff = JSON.parse(JSON.stringify(this.pageSettings))

      // Add lasted edited user to the page object
      if (this.loggedInUser) {
        parsedPage.last_edited_by = this.loggedInUser
      }

      this.updatePageSettings(parsedPage)
        .then((resp) => {
          if (resp.success) {
            this.toastSuccess("Successfully updated page.")

            var diffedData = ""
            try {
              delete pageDiff.last_edited_by
              delete pageDiff.style_option
              delete pageOrigDiff.last_edited_by
              delete pageOrigDiff.style_option
              pageDiff = this.removeUneededPageItems(pageDiff)
              pageOrigDiff = this.removeUneededPageItems(pageOrigDiff)
              diffedData = this.$jsonDiff.diffString(pageOrigDiff, pageDiff)
            } catch (e) {
              console.error("🚀 ~ file: website-editor.vue ~ line 923 ~ .then ~ e, pageOrigDiff, pageDiff", e, pageOrigDiff, pageDiff)
            }

            this.$root.setWebsiteChanges(`Update Page: ${ parsedPage.name } - ${ parsedPage.route }`, diffedData, 'editor_page').then((id) => {
              let versionHistory = {}
              versionHistory.page_version = resp.page.version
              versionHistory.page_object = JSON.stringify(resp.page)
              versionHistory.page_id = resp.page.id
              versionHistory.created_at = new Date()
              versionHistory.changelog_id = id
              // delete versionHistory.last_edited_by
              versionHistory.last_edited_by_id = this.loggedInUser.id
              versionHistory.last_edited_by_name = this.loggedInUser.name
              versionHistory.last_edited_by_email = this.loggedInUser.email
              this.$db.collection(`page_version_history/${ resp.page.website_id }/history`).add(versionHistory).then((docRef) => {

              }).catch((e) => {
                console.log(e)
              })
            })
            // Recache updates
            this.cacheSettings()

          }

          if (!resp.success) {
            this.toastErrorAlt("An error occurred when updating the page.")
          }
        })
        .catch((err) => {
          console.error("error: ", err)
          this.$rollbar.error('WebsiteEditor: Error updating the page', err, { account_id: this.selectedAccountId, website_id: this.selectedWebsite.id, page_id: this.pageSettings.id })
          this.toastErrorAlt("An error occurred when updating the page.")
        })
        .finally(() => {
          this.isLoading = false
          this.$wait.end("page-saving")
        })
    },
    /**
     * Redirect user to edit page for selected location page
     */
    onSelectPage(page) {
      const baseEditorRoute = `/website-manager/${ this.selectedAccountId }/editor`
      if (page.action) {
        // Redirect user to add page
        this.$router.replace(`${ baseEditorRoute }/${ page.action }`, () => {
          // After route navigation completes
          // We use the go router method to refresh the page, so
          // the page reloads and we reset the `hasChanges` method
          this.$router.go()
        })
      } else {
        // Redirect user to edit page
        this.$router.replace(`${ baseEditorRoute }/${ page.id }`, () => {
          // After route navigation completes
          // We use the go router method to refresh the page, so
          // the page reloads and we reset the `hasChanges` method
          this.$router.go()
        })
      }
    },
    removeUneededPageItems(page) {
      delete page["created_at"]
      delete page["updated_at"]
      delete page["version"]
      delete page["deletedAt"]
      delete page["type"]
      delete page["style_object"]

      if (page["sections_object"]) {
        let sections = []
        for (let sect of page["sections_object"]) {
          if (!sect.is_global) sections.push(sect)
        }
        page["sections_object"] = sections

        for (let section of page["sections_object"]) {
          delete section["name"]
          if (section["rows"]) {
            for (let row of section["rows"]) {
              delete row["name"]
              if (row["column_widths"]) {
                for (let column_width of row["column_widths"]) {
                  if (column_width["components"]) {
                    for (let component of column_width["components"]) {
                      component = this.removeUneededItemsFromComponent(component)
                      delete component["image"]
                      if (component["schema"]["prop_schemas"]) delete component["schema"]["prop_schemas"]
                      if (component["schema"]["image"]) delete component["schema"]["image"]
                    }
                  }
                }
              }
            }

          }
        }
      }

      return page
    },
    removeUneededItemsFromComponent(component) {
      // delete component["id"]
      delete component["created_at"]
      delete component["updated_at"]
      delete component["version"]
      delete component["info"]
      delete component["description"]
      delete component["is_global"]
      delete component["global_section_id"]
      if (component["schema"]) {
        delete component["schema"]["id"]
        delete component["schema"]["created_at"]
        delete component["schema"]["updated_at"]
        delete component["schema"]["version"]
        delete component["schema"]["bit_component_url"]
        delete component["schema"]["info"]
        delete component["schema"]["short_name"]
        delete component["schema"]["description"]
        delete component["schema"]["type"]
        delete component["schema"]["code"]
        delete component["schema"]["is_schema"]
      }
      if (component["style_option"]) {
        delete component["style_option"]["id"]
        delete component["style_option"]["created_at"]
        delete component["style_option"]["updated_at"]
        delete component["style_option"]["version"]
      }
      if (component["props"]) {
        for (let prop of component["props"]) {
          delete prop["id"]
          delete prop["created_at"]
          delete prop["updated_at"]
          delete prop["version"]
          if (prop["schema"]) {
            delete prop["schema"]["id"]
            delete prop["schema"]["created_at"]
            delete prop["schema"]["updated_at"]
            delete prop["schema"]["version"]
            delete prop["schema"]["label"]
            delete prop["schema"]["info"]
            delete prop["schema"]["is_required"]
            delete prop["schema"]["is_schema"]
            delete prop["schema"]["size"]
            delete prop["schema"]["type"]
            delete prop["schema"]["options"]
            delete prop["schema"]["children"]
            delete prop["schema"]["limit"]
          }
          if (prop["children"]) {
            for (let child of prop["children"]) {
              delete child["id"]
              delete child["created_at"]
              delete child["updated_at"]
              delete child["version"]
              if (child["schema"]) {
                delete child["schema"]["id"]
                delete child["schema"]["created_at"]
                delete child["schema"]["updated_at"]
                delete child["schema"]["version"]
                delete child["schema"]["label"]
                delete child["schema"]["info"]
                delete child["schema"]["is_required"]
                delete child["schema"]["is_schema"]
                delete child["schema"]["size"]
                delete child["schema"]["type"]
                if (child["schema"]["options"]) delete child["schema"]["options"]
              }
            }
          }
        }
      }
      return component
    }
  },
}
