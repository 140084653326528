
import DarkModeMixin from "@/mixins/dark-mode"

export default {
  name: "LoginLayout",
  mixins: [DarkModeMixin],
  mounted() {
    this.$nextTick(() => {
      window.Intercom('onShow', (e) => {
        console.log("🚀 ~ file: login.vue ~ line 16 ~ window.Intercom ~ e", e)

      })
    })
  }
}
