
import { mapGetters, mapMutations, mapActions } from "vuex"
import { FadeTransition } from "vue2-transitions"
import Search from "@/dashboard/search/sandwich-search"
import PopupActivityCard from "@/dashboard/cards/popup-activity-card"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
// import io from "socket.io-client"
import { PubSub } from "aws-amplify"
import localforage from "localforage"
import DarkModeMixin from "@/mixins/dark-mode"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"
import * as _ from "lodash"
const AOS = require("aos")
import Multiselect from "vue-multiselect"
import { format, subMonths } from "date-fns"
import AlgoliaSearch from "@/dashboard/search/algolia-search"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"


export default {
  name: "DefaultLayout",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    Multiselect,
    InsightsSidenavGlobal,
    PopupActivityCard,
    AlgoliaSearch,
    NavbarBananaPage,
    NavbarBananaPageMobile
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      layoutSearchString: "",
      socket: null,
      socketStatus: {},
      subSuccess: null,
      subError: null,
      selectedDateValue: null,
      dateOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      searchString: "leads/searchString",
      isResetAccount: "layoutVars/getIsResetAccount",
      // isDarkMode: "layoutVars/getIsDarkMode",
      user: "user/getUser",
      selectedAccount: "accounts/getSelectedAccount",
      isImageUploading: "media/getIsUploading",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      uploadingErrorData: "media/getIsUploadingErrorData",
    }),
  },
  async created() {
    let thisMonth = format(new Date(), "MM")
    let quarter = format(new Date(), "Q")
    let quarterLast = format(subMonths(new Date(), 3), "Q")
    let quarterLastYear = format(subMonths(new Date(), 3), "yyyy")
    let thisQuarter = ""
    let thisQuarterEnd = ""
    let lastQuarter = ""
    let lastQuarterEnd = ""
    let thisYear = format(new Date(), "yyyy")
    if (quarter == 1) {
      thisQuarter = "01"
      thisQuarterEnd = "03"
    } else if (quarter == 2) {
      thisQuarter = "04"
      thisQuarterEnd = "06"
    } else if (quarter == 3) {
      thisQuarter = "07"
      thisQuarterEnd = "09"
    } else if (quarter == 4) {
      thisQuarter = "10"
      thisQuarterEnd = "12"
    }
    if (quarterLast == 1) {
      lastQuarter = "01"
      lastQuarterEnd = "03"
    } else if (quarterLast == 2) {
      lastQuarter = "04"
      lastQuarterEnd = "06"
    } else if (quarterLast == 3) {
      lastQuarter = "07"
      lastQuarterEnd = "09"
    } else if (quarterLast == 4) {
      lastQuarter = "10"
      lastQuarterEnd = "12"
    }
    let lastMonth = format(subMonths(new Date(), 1), "MM")
    this.dateOptions = [
      { name: "This month", value: `#${ thisYear }-${ thisMonth }` },
      { name: "Last month", value: `#${ thisYear }-${ lastMonth }` },
      { name: "This quarter", value: `${ thisYear }-${ thisQuarter }-01_${ thisYear }-${ thisQuarterEnd }-31` },
      { name: `Last quarter`, value: `${ quarterLastYear }-${ lastQuarter }-01_${ quarterLastYear }-${ lastQuarterEnd }-31` },
      { name: "All time", value: "" },
    ]
    this.$store.commit("leads/updateContactEventDateFilter", this.dateOptions[4])
    this.selectedDateValue = _.cloneDeep(this.$store.getters["leads/contactEventDateFilter"])
  },
  async mounted() {
    // this.listenForWebsocket()

    // if (this.isDarkMode === true) {
    //   document.getElementById("__nuxt").classList.add("pug-dark-mode")
    // } else {
    //   document.getElementById("__nuxt").classList.add("bg-light")
    // }
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 0, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-center", // defines which position of the element regarding to window should trigger the animation
    })
    this.$nextTick(async () => {

      this.loadUnitsForLocations()

      AOS.refreshHard()
      let cacheExpires = await this.$vlf.getItem("cache_expires")
      if (cacheExpires === null || cacheExpires < Date.now()) {
        var ONE_HOUR = 60 * 60 * 1000
        var EXPIRE_TIME = ONE_HOUR * 24
        var expires = new Date(new Date().getTime() + EXPIRE_TIME)
        localforage.clear()
        setTimeout(async () => {
          await this.$vlf.setItem("cache_expires", expires)
        })
      }
    })
  },
  destroyed() {
    // this.subSuccess.unsubscribe()
    // this.subError.unsubscribe()
  },
  watch: {
    layoutSearchString() {
      let tmp = _.cloneDeep(this.layoutSearchString)
      this.$store.commit("leads/updateSearchString", tmp)
    },
    uploadingError() {
      if (this.uploadingError === true) {
        let msg = `${ this.uploadingErrorData.length } images failed! `
        for (const item of this.uploadingErrorData) {
          msg += " " + item.file_name_original
        }
        this.toastError(msg, true)
      }
    },
    isImageUploading() {
      if (this.isImageUploading === true) {
        this.$nuxt.$loading.start()
      } else {
        this.$nuxt.$loading.finish()
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
      updateSearchItems: "layoutVars/updateSearchItems",
    }),
    ...mapActions({
      loadUnitsForLocations: "unitManager/loadUnitsForLocations",
    }),
    updateDateFilter(e) {
      // console.log("updateDateFilter -> e", e)
      let tmp = _.cloneDeep(e)
      this.$store.commit("leads/updateContactEventDateFilter", tmp)
    },
    getCookie(name) {
      var value = "; " + document.cookie
      var parts = value.split("; " + name + "=")
      if (parts.length == 2) return parts.pop().split(";").shift()
    },
    goBack() {
      this.$router.push(`/leads/${ this.selectedAccountId }`)
    },
    listenForImageUploadEvents() {
      PubSub.subscribe("image upload error").subscribe({
        next: (data) => {
          this.toastError(`Image upload error: ${ data }`, true)
        },
        error: (error) => console.error(error),
      })
    },
    handleSelected(selected) {
      if (selected !== null) {
        this.updateSearchItems(selected)
      } else {
        this.updateSearchItems([])
      }
    },
    listenForWebsocket() {
      this.subSuccess = PubSub.subscribe("update_cache_success").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            // this.updateUnitCacheStatus({
            //   account_id: this.selectedAccountId,
            //   status: 'success'
            // })
            this.$bvToast.toast(data.value.message, {
              title: "Success",
              autoHideDelay: 8000,
              variant: "success",
              toaster: "b-toaster-bottom-right",
            })
            // }
          } catch (e) {
            // this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error('Leads CacheSuccess: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
      this.subError = PubSub.subscribe("update_cache_error").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            this.updateUnitCacheStatus({
              account_id: this.selectedAccountId,
              status: "error",
            })
            this.toastError(data.value.message, true)
            // }
          } catch (e) {
            this.$rollbar.error('Leads CacheError: Error updating cache status', e, { account_id: this.selectedAccountId })
          }
        },
        error: (error) => {
          this.$rollbar.error('Leads CacheError: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
    },
  },
}
