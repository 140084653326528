import { render, staticRenderFns } from "./globalCalendar.vue?vue&type=template&id=67516d3e&lang=pug"
import script from "./globalCalendar.vue?vue&type=script&lang=js"
export * from "./globalCalendar.vue?vue&type=script&lang=js"
import style0 from "./globalCalendar.vue?vue&type=style&index=0&id=67516d3e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/helpers/navigation/Sidebar.vue').default,IdleLayover: require('/helpers/IdleLayover.vue').default,NavBar: require('/helpers/navigation/NavBar.vue').default,NavbarBananaPage: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPage.vue').default,NavbarBananaPageMobile: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile.vue').default})
